import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  
  @HostBinding('class') classes = 'd-block';
  @Input() title = "";
  constructor() { }

  ngOnInit(): void {
  }

}
