<div class="card">

    <div class="card-header">
        {{title}}
    </div>

    <div class="card-body">
        <ng-content></ng-content>
    </div>

    <div class="card-footer">
        <i>
            <ng-content select="[panel-footer]"></ng-content>
        </i>
    </div>
</div>